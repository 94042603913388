import request from '@/utils/request';


// StockCheckOrder
export function stockCheckOrderList(params) {
  return request({ url: `/stock_check_orders/`, method: 'get', params })
}

export function stockCheckOrderRetrieve(params) {
  return request({ url: `/stock_check_orders/${params.id}/`, method: 'get', params })
}

export function stockCheckOrderCreate(data) {
  return request({ url: `/stock_check_orders/`, method: 'post', data })
}

export function stockCheckOrderMaterials(params) {
  return request({ url: `/stock_check_orders/${params.id}/stock_check_materials/`, method: 'get', params })
}

export function stockCheckOrderVoid(data) {
  return request({ url: `/stock_check_orders/${data.id}/void/`, method: 'post', data })
}

export function stockCheckOrderPrint(data) {
  return request({ url: `/stock_check_orders/print_data/`, method: "post", data });
}

// StockCheckMaterial
export function stockCheckMaterialList(params) {
  return request({ url: `/stock_check_materials/`, method: 'get', params })
}

// StockCheckRecord
export function stockCheckRecordList(params) {
  return request({ url: `/stock_check_records/`, method: 'get', params })
}

export function stockCheckRecordRetrieve(params) {
  return request({ url: `/stock_check_records/${params.id}/`, method: 'get', params })
}

export function stockCheckRecordCreate(data) {
  return request({ url: `/stock_check_records/`, method: 'post', data })
}

export function stockCheckRecordMaterials(params) {
  return request({ url: `/stock_check_records/${params.id}/stock_check_record_materials/`, method: 'get', params })
}

export function stockCheckRecordVoid(data) {
  return request({ url: `/stock_check_records/${data.id}/void/`, method: 'post', data })
}

// StockCheckRecordMaterial
export function stockCheckRecordMaterialList(params) {
  return request({ url: `/stock_check_record_materials/`, method: 'get', params })
}